import React, { useState, useEffect } from "react";
import moment from "moment";
import { useWindowSize } from "./components/hooks";
import Modal from 'react-modal';
import SearchForm from "./components/SearchForm/SearchForm";
import ErrorBoundary from "./components/ErrorBoundary";
import ResultContent from "./components/ResultContent/ResultContent";
import "./reservation.css";
import { names } from "./Styles/names";
import { blockPageBody, unblockPageBody } from "./components/functions";

function ReservationContainer() {
  const size = useWindowSize();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(moment().add(1, "days")));
  const [availableDates, setAvailableDates] = useState([]);
  const [adultCount, setAdultCount] = useState(2);
  const [childCount, setChildCount] = useState([]);
  const [roomTypes, setRoomTypes] = useState({});
  const [roomTypeId, setRoomTypeId] = useState(0);
  const [rateId, setRateId] = useState(0);
  const [result, setResult] = useState([]);
  const [subscriptionParams, setSubscriptionParams] = useState({});
  const [loader, setLoader] = useState(false);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [rates, setRates] = useState([]);
  const [modal, setModal] = useState({
    show: false,
    title: "",
    body: "",
    size: "sm",
    className: "",
    dialogClassName: ""
  });
  const [alertText, setAlertText] = useState("");
  const [agutinText, setAgutinText] = useState(null);
  const [alt, setAlt] = useState(null);
  const [isAlertBoxShown, setIsAlertBoxShown] = useIsAlertBoxShown(alertText, agutinText);
  const [isDataTimeout, setIsDataTimeout] = useState(false);
  const [isShowPreloaderOnly, setIsShowPreloaderOnly] = useState(Boolean(window.isLoaded));

  const url = new URL(window.location.href);
  const promo = url.searchParams.get("promo");

  useEffect(() => {
    if (isShowPreloaderOnly) {
      const date_in = url.searchParams.get("date");
      const date_out = url.searchParams.get("date_out");
      const isDatesInUrl = Boolean(date_in) && Boolean(date_out)


      if (!isDatesInUrl || result.length) {
        setIsShowPreloaderOnly(false)
      }
    }
  }, [result, isShowPreloaderOnly])

  useEffect(
      () => {
        setAgutinText(null)
        if (alt) {
          let stop = false
          alt.forEach(a => {
            if (!stop) {
              JSON.parse(a.dates).forEach(d => {
                if (!stop) {
                  if (
                      (d.startDate <= moment(startDate).format("yyyy-MM-DD") && moment(startDate).format("yyyy-MM-DD") <= d.endDate)
                      ||
                      (d.startDate < moment(endDate).format("yyyy-MM-DD") && moment(endDate).format("yyyy-MM-DD") <= d.endDate)
                  ) {
                    setAgutinText(a.text)
                    stop = true
                    return
                  }
                }
              })
            }
          })
          if (!stop) {
            setAgutinText(null)
          }
        }
      }, [alt]
  )

  //создаем mindbox 1 раз
  useEffect(()=> {
    const mindbox = window.mindbox || function () {
      mindbox.queue.push(arguments);
    };

    mindbox.queue = mindbox.queue || [];

    mindbox('create', {
      firebaseMessagingSenderId: process.env.REACT_APP_MINDBOX_LOGIN
    });
  },[])

  let hotel_id = 11;
  let product_id = 48;
  let resource_id = 48;

  if (typeof window.hotel_id != "undefined" && window.hotel_id !== null) {
    hotel_id = window.hotel_id;
  }

  if (typeof window.product_id != "undefined" && window.product_id !== null) {
    product_id = window.product_id;
  }

  if (typeof window.resource_id != "undefined" && window.resource_id !== null) {
    resource_id = window.resource_id;
  }

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setShowDatepicker(false);
    }
  };

  const onModalOpen = (show, title, body, size = "sm", dialogClassName) => {
    blockPageBody()
    setModal({ show, title, body, size, dialogClassName });
  };

  const onModalClose = () => {
    unblockPageBody()
    setModal({ show: false });
  };

  return (
    <React.Fragment>
      <ErrorBoundary>
        <SearchForm
          startDate={startDate}
          endDate={endDate}
          adultCount={adultCount}
          childCount={childCount}
          roomTypeId={roomTypeId}
          rateId={rateId}
          showDatepicker={showDatepicker}
          loader={loader}
          result={result}
          hotelId={hotel_id}
          rates={rates}
          size={size}
          resourceId={resource_id}
          onShowDatepickerChange={setShowDatepicker}
          setAvailableDates={setAvailableDates}
          onAdultCount={setAdultCount}
          onChildCount={setChildCount}
          onRoomTypeIdChange={setRoomTypeId}
          onRateIdChange={setRateId}
          onDateChange={onDateChange}
          onLoaderChange={setLoader}
          onResultChange={setResult}
          onRatesChange={setRates}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setAlertText={setAlertText}
          setAlt={setAlt}
          roomTypes={roomTypes}
          setRoomTypes={setRoomTypes}
          setSubscriptionParams={setSubscriptionParams}
          setIsAlertBoxShown={setIsAlertBoxShown}
          isAlertText={Boolean(alertText || agutinText)}
          setIsDataTimeout={setIsDataTimeout}
          promo={promo}
          isContentHidden={isShowPreloaderOnly}
        />
        <div className={`ur-container${size.width < 576 ? " ur-px-8" : ""} ${names.backgrond.primary}`}>
          <ResultContent
              result={result}
              loader={loader}
              productId={product_id}
              hotelId={hotel_id}
              roomTypeId={roomTypeId}
              onModalOpen={onModalOpen}
              onModalClose={onModalClose}
              availableDates={availableDates}
              alertText={alertText}
              agutinText={agutinText}
              roomTypes={roomTypes}
              setRoomTypes={setRoomTypes}
              subscriptionParams={subscriptionParams}
             isAlertBoxShown={isAlertBoxShown}
             isDataTimeout={isDataTimeout}
             setIsDataTimeout={setIsDataTimeout}
             isShowPreloaderOnly={isShowPreloaderOnly}
          />
        </div>
        <Modal
            isOpen={modal.show}
            onRequestClose={onModalClose}
          className={`modal__bk ${modal.dialogClassName} ${names.backgrond.primary}`}
            overlayClassName="modal-overlay"
            shouldCloseOnOverlayClick={true}
            size={modal.size}
        >
          <div className="modal-header">
            <h2
              className={names.text.secondary}
              style={{ textAlign: 'center', fontSize: '18px', lineHeight: 1, marginRight: '188px' }}
            >
              {modal.title}
            </h2>
            <button onClick={onModalClose} className={`modal-close-button ${names.text.secondary}`}>
              ×
            </button>
          </div>
          <div className="modal-body">
            {modal.body}
          </div>
        </Modal>

      </ErrorBoundary>
    </React.Fragment>
  );
}

export default ReservationContainer;


const shouldShowAlertSwitch = process.env.REACT_APP_SHOULD_SHOW_ALERT_SWITCH === 'true';

const useIsAlertBoxShown = (alertText, agutinText, defaultValue = false) => {
  const [isAlertBoxShown, setIsAlertBoxShown] = useState(defaultValue);

  const { width } = useWindowSize()

  useEffect(() => {
    if (alertText || agutinText) {
      if (shouldShowAlertSwitch) {
        if (width < 960) {
          setIsAlertBoxShown(true)
        }
      } else {
        setIsAlertBoxShown(true)
      }
    } else {
      setIsAlertBoxShown(false)
    }
  }, [width, alertText, agutinText])

  return [isAlertBoxShown, setIsAlertBoxShown]


}