import './IconButton.css'
import { names } from '../../../Styles/names';

const DEFAULT_CLASSNAME = 'icon-button'

const IconButton = ({
  iconClassName,
  className,
  onPress,
    text,
                      classBackground
}) => {
  const classNames = className
    ? `${DEFAULT_CLASSNAME} ${className}`
    : DEFAULT_CLASSNAME

  return <button
    onClick={onPress}
    className={classNames + ' ' + classBackground}
  >
    <i className={`icon-button__icon ${classBackground} ${iconClassName}`} />
    <span className={'inter__family'}>{text}</span>
  </button>
}

export default IconButton