import React, {useEffect, useState} from "react";
import moment from "moment";
import "moment/locale/ru";
import { Popover, Whisper } from "rsuite";
import HotelRate from "../HotelRate/HotelRate";
import Carousel from "react-multi-carousel";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import "react-multi-carousel/lib/styles.css";
import "./style.css";
import Popup from "reactjs-popup";
import { names } from "../../Styles/names";

function HotelItem(props) {
  const [isHotelOpen, setHotelOpen] = useState(false);
  const [showShareRRid, setShowShareRRid] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);

  const customTooltip = {
    background: "#1F1F1F",
    borderRadius: "16px",
    color: "#FFFFFF",
    borderColor: "#1F1F1F"
  }

  useEffect(() => {
    if (props.result?.room_type_id)
      setShowShareRRid(props.result?.room_type_id);
  }, [props.result.room_type_id]);

  if (!props.result.gallery_images) return <></>;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function getStyle(name) {
    return {
      width: "calc(100% - 12px)",
      height: "56px",
      margin: "2px 6px",
      backgroundImage: name.rate_bg
        ? "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(" +
          process.env.PUBLIC_URL +
          name.rate_bg +
          ")"
        : "",
      backgroundSize: "cover",
      color: name.rate_bg ? "#fff" : "#333",
    };
  }

  let childrenInfo = ''

  if (props.result.children_info.quantity === 1)
    childrenInfo = ", 1 ребенок"
  else if (props.result.children_info.quantity <= 4 && props.result.children_info.quantity > 0)
    childrenInfo = `, ${props.result.children_info.quantity} ребенка`
  else if (props.result.children_info.quantity > 4)
    childrenInfo = `, ${props.result.children_info.quantity} детей`

  const guestsInfo = props.result.adults + " " + (props.result.adults > 1 ? "взрослых" : "взрослый") + childrenInfo

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, slidesToShow, totalItems },
    } = rest;
    return (
      <div className="carousel-button-group">
        <button
          className={`nav-left${currentSlide === 0 ? " ur-d-none" : ""}`}
          onClick={() => previous()}
        >
          <i className="icon icon-arrow-left"></i>
        </button>
        <button
          className={`nav-right${
            currentSlide + slidesToShow >= totalItems ? " ur-d-none" : ""
          }`}
          onClick={() => next()}
        >
          <i className="icon icon-arrow-right"></i>
        </button>
      </div>
    );
  };

  const diff = moment(props.result.end).diff(
    moment(props.result.begin),
    "days"
  );

  const services = (props) => {
    const servs = [];
    props.forEach((p) => {
      let stop = false;
      servs.forEach((s) => {
        if (s.rate_id === p.rate_id) stop = true;
      });
      if (!stop) servs.push(p);
    });
    return servs.map((name, idx) => (
      <div
        key={idx}
        className={`rates-item ${names.border.tertiaryDark}`}
        style={getStyle(name)}
      >
        <p className={names.text.primary}>
          {name.rate_name.replace(/<br\s*\/?>/gi, " ")}
        </p>
      </div>
    ));
  };

  const minPrice = [];

  const rateSort = props.result.rate_group.sort((a, b) => {
    if (a.promo !== null && b.promo === null) {
      return -1; // a с promo должен быть первым
    } else if (a.promo === null && b.promo !== null) {
      return 1; // b с promo должен быть первым
    } else if (a.quotas_vacant === 0 && b.quotas_vacant !== 0) {
      return 1; // b без own_quotas должен быть первым
    } else if (a.quotas_vacant !== 0 && b.quotas_vacant === 0) {
      return -1; // a без own_quotas должен быть первым
    } else {
      return a.order_rate - b.order_rate; // оба элемента имеют или не имеют promo, сортируем по order_rate
    }
  });


  let mainQuotasVacant = 0
  let stopGeneral = false //после добавления первой общей квоты, не плюсуем
  rateSort.forEach((rate) => {
    if (rate.total_discount.price !== 0)
      minPrice.push(rate.total_discount.price)
    else
      minPrice.push(rate.rate_price)

    if (rate.own_quotas) {
      mainQuotasVacant += rate.quotas_vacant
    } else if (!stopGeneral) {
      mainQuotasVacant += rate.quotas_vacant
      stopGeneral = true
    }
  });

  const minCost = Math.min.apply(null, minPrice);

  const hotelRates = rateSort.map((rate, idx) => {

    return (
        <HotelRate
            rate={{
              id: rate.rate_id,
              name: rate.rate_name,
              promo: rate.promo,
              description: rate.rate_description,
              background: rate.rate_bg,
              price: Math.round(rate.rate_price),
              services: rate.rate_services,
              food: rate.food,
              max_stay: rate.max_stay,
              multiple_days: rate.multiple_days,
              extraBed: rate.extraBed,
              craddle: rate.craddle,
              prices: rate.prices,
              related_rate: rate.related_rate,
              dateIn: rate.dateIn,
              dateOut: rate.dateOut,
              discount: rate.discount,
              tariff_characteristics: rate.tariff_characteristics,
              room_rates: rate.room_rates,
              promo_id: rate?.promo_id,
              total_discount: rate?.total_discount,
              tariff_after: rate?.tariff_after,
              tariff_before: rate?.tariff_before,
              loyalty: rate?.loyalty,
              payment_methods_oob: rate.payment_methods_oob,
              stocks: rate.stocks,
            }}
            productId={props.productId}
            hotelId={props.hotelId}
            room_type_id={props.result.room_type_id}
            room_type_name={props.result.room_type_name}
            total={Math.round(props.result.total)}
            date_in={props.result.date_in}
            date_out={props.result.date_out}
            amount={rate.quotas_vacant}
            own_quotas={rate.own_quotas}
            diff={diff}
            adults={props.result.adults}
            children={props.result.children}
            children_info={props.result.children_info}
            babies={props.result.babies}
            rooms={props.result.rooms}
            image={props.result.image}
            key={idx}
            onModalOpen={props.onModalOpen}
            onModalClose={props.onModalClose}
            declOfNum={declOfNum}
            extraPlace={rate.extraPlace}
            cradlePlace={props.result.cradlePlace}
            guestsInfo={guestsInfo}
            modalGallery={
              props.upsaleProduct && <ImagesGallery
                mainImage={props.upsaleProduct.image}
                imageDescription={props.upsaleProduct.room_type_name}
                images={props.upsaleProduct.gallery_images}
              />}
            upsaleProduct={props.upsaleProduct}
            syncMindBox={props.syncMindBox}
        />
    );
  });

  function hotelOpen(e) {
    e.preventDefault();
    setHotelOpen(!isHotelOpen);
  }

  function declOfNum(number, titles) {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }

  function copyClipboard(text, roomTypeId) {
    const modifiedUrl = text.replace(/&room_type_id=\d+/, "&room_type_id=" + roomTypeId);
    navigator.clipboard.writeText(modifiedUrl).then(function() {
      props.onModalOpen(
        true,
        "Внимание!",
        "Ссылка на номер скопирована в буфер обмена!",
      )
    }, function(err) {
      console.error('Could not copy text: ', err);
    });

  }

  function shareRoom(title, roomTypeId) {
    if (false && navigator.canShare) { // Временно шеринг выключен
      navigator.share({'url':window.location.href,'title':title})
      .then(() => console.log('Share was successful.'))
      .catch((error) => {console.log('Sharing failed', error); copyClipboard(window.location.href); });
    } else {
      console.log(`Your system doesn't support sharing files.`);
      copyClipboard(window.location.href, roomTypeId);
    }

  }

  function getDiscountNum(num, discount) {
    return Math.round((num / 100) * discount * 100) / 100;
  }

  function ImagesGallery(props) {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleImageClick = (index) => {
      setCurrentIndex(index);
      setLightboxOpen(true);
    };

    const handleCloseLightbox = () => {
      setLightboxOpen(false);
      setCurrentIndex(0);
    };

    return (
        <div className="images-gallery">
          <div>
            <div className="main-image" onClick={() => handleImageClick(0)}>
              {
                props.mainImage === ''
                    ?
                    <div className="ur-text-center">
                      <div className="progress-circle"/>
                    </div>
                    :
                    <img src={process.env.REACT_APP_API_URL_DIRECTLY + props.mainImage.link} alt={props.imageDescription} loading="lazy"/>
              }
            </div>
            <div className="thumbs-images">
              {props?.images?.slice(1, 5).map((image, idx) => (
                  <img
                      key={idx}
                      src={process.env.REACT_APP_API_URL_DIRECTLY + image.link}
                      className="react-lightbox"
                      alt=""
                      onClick={() => handleImageClick(idx + 1)}
                      loading="lazy"
                  />
              ))}
              {props?.images?.length > 5 && (
                  <button className="more-photo" onClick={() => handleImageClick(5)}>
                    Еще фото
                  </button>
              )}
            </div>
          </div>
          {props.showShareRRid && (
              <button
                  className="share-room-link-botton"
                  onClick={() => shareRoom(props.imageDescription, props.showShareRRid)}
              >
                <i className="icon icon-share" />
              </button>
          )}
          {lightboxOpen && props?.images?.length > 0 && (
              <Lightbox
                  mainSrc={process.env.REACT_APP_API_URL_DIRECTLY + props.images[currentIndex].link}
                  nextSrc={process.env.REACT_APP_API_URL_DIRECTLY + (props.images[(currentIndex + 1) % props.images.length].link)}
                  prevSrc={process.env.REACT_APP_API_URL_DIRECTLY + (props.images[(currentIndex + props.images.length - 1) % props.images.length].link)}
                  onCloseRequest={handleCloseLightbox}
                  onMovePrevRequest={() => setCurrentIndex((currentIndex + props.images.length - 1) % props.images.length)}
                  onMoveNextRequest={() => setCurrentIndex((currentIndex + 1) % props.images.length)}
              />
          )}
        </div>
    );
  }

  const subscriptionButton =
      <div onClick={() => props.subscriptionHandle(props.result.room_type_id)} className={"subscription-button"}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M18.1795 13.727L19.1546 15.221C19.5349 15.797 19.6031 16.481 19.3496 17.102C19.0961 17.723 18.5695 18.2 17.887 18.407C15.9954 18.992 14.0063 19.28 12.0171 19.28C10.028 19.28 8.03888 18.992 6.14726 18.398C5.42572 18.173 4.86993 17.705 4.63592 17.102C4.39215 16.49 4.47991 15.824 4.86993 15.221L5.85475 13.718C6.05951 13.394 6.24477 12.764 6.24477 12.395V10.118C6.24477 8.02998 7.5611 6.22098 9.46247 5.34798C9.9695 4.51098 10.9251 3.97998 11.9879 3.97998C13.0604 3.97998 13.9965 4.49298 14.5035 5.32098C16.4439 6.17598 17.7895 8.00298 17.7895 10.118V12.395C17.7895 12.764 17.9748 13.394 18.1795 13.727ZM11.9977 21.9799C13.2653 21.9799 14.3476 21.2329 14.7572 20.1889C14.5914 20.2069 14.4354 20.2249 14.2794 20.2429C14.1477 20.2612 14.0115 20.2712 13.874 20.2813C13.8078 20.2862 13.7413 20.2911 13.6748 20.2969C13.1288 20.3419 12.573 20.3689 12.0172 20.3689C11.4517 20.3689 10.8862 20.3419 10.3304 20.2969C10.0964 20.2789 9.86234 20.2519 9.63808 20.2249C9.57391 20.2165 9.51189 20.21 9.45101 20.2036C9.38239 20.1965 9.31522 20.1895 9.24806 20.1799C9.38456 20.5489 9.61858 20.9089 9.9306 21.1789C10.4669 21.6919 11.2274 21.9799 11.9977 21.9799Z"
                fill="white"/>
        </svg>
      </div>

  return (
    <div className={`result-container__hotel ${names.border.tertiary}${isHotelOpen ? " open" : ''}`}>
        <div
            className="result-container__hotel-main"
        style={{
          padding: "5px",
        }}
      >
        <ImagesGallery
					mainImage={props.result.image}
					imageDescription={props.result.room_type_name}
					images={props.result.gallery_images}
                    showShareRRid={showShareRRid}
				/>
        <div className="hotel-content">
          <div
            className={`item-position-absolute hotel-content__last-number${
                mainQuotasVacant > 0 && mainQuotasVacant < 5 ? "" : " ur-d-none"
            }`}
          >
            {/*{mainQuotasVacant <= 0 && "Нет свободных номеров"}*/}
            {mainQuotasVacant === 1 && "Остался всего 1 номер"}
            {mainQuotasVacant >= 2 &&
              "Осталось всего " + mainQuotasVacant + " номера"}
          </div>
          <div className="hotel-content__title">
            <button
              onClick={hotelOpen}
              className={names.text.secondary}
            >
              {props.result.room_type_name}
            </button>
          </div>
          <div
            className={`hotel-content__description ${names.text.secondary}`}
            dangerouslySetInnerHTML={{
              __html: props.result.room_type_description.replace(
                /<\/?[^>]+>/gi,
                ""
              ),
            }}
          ></div>
          <div className="hotel-content__options">
            <div className={`options-item ${names.text.secondary}`}>
              <i className="icon icon-guest"></i> до{" "}
              {props.result.room_type_capacity} человек
            </div>
            <div className={`options-item ${names.text.secondary}`}>
              <i className="icon icon-area"></i> Площадь{" "}
              {props.result.room_type_square[0] ===
              props.result.room_type_square[1] ? (
                <>{props.result.room_type_square[0]}м²</>
              ) : (
                <>
                  {props.result.room_type_square[0]}м²
                  {" - "}
                  {props.result.room_type_square[1]}м²
                </>
              )}
            </div>
            <button
              className={`features__button ${names.text.accentNegative}`}
              onClick={() => setShowFeatures(pr => !pr)}
            >
              {showFeatures ? 'Скрыть' : 'Показать'} список удобств
            </button>
          </div>

          {showFeatures && props.result?.room_feature_categories && (
              <div className="feature__container">
                {Object.entries(props.result?.room_feature_categories).map(([key, value]) => (
                    <div className="feature__element" key={key}>
                    <div className={`feature__category__name ${names.text.tertiary}`}>{key}</div>
                    <div className={`features ${names.backgrond.secondary}`}>
                        {value.map(v => (
                            <div className="internal__feature" key={v.icon}>
                            <img
                              src={`${process.env.REACT_APP_API_URL_DIRECTLY}feature_icons/${encodeURIComponent(v.icon)}`}
                              alt="" loading="lazy"
                            />
                            <div className={`feature__name ${names.text.primary}`}>
                              {v.name}
                            </div>
                            </div>
                        ))}
                      </div>
                    </div>
                ))}
              </div>
          )}

          <div className="hotel-content__rates">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={false}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              {services(rateSort)}
            </Carousel>
          </div>
          <div className="hotel-content__footer">
            <div className={`col-desc ${names.text.secondary}`}>
              {props.result.adults}{" "}
              {props.result.adults > 1 ? "взрослых" : "взрослый"}
              {props.result.children_info.quantity === 1
                ? ", 1 ребенок"
                : props.result.children_info.quantity <= 4 &&
                  props.result.children_info.quantity > 0
                ? `, ${props.result.children_info.quantity} ребенка`
                : props.result.children_info.quantity > 4
                ? `, ${props.result.children_info.quantity} детей`
                : ""}
              <br />с {moment(props.result.date_in).format("DD MMMM")}, на{" "}
              {diff} {declOfNum(diff, ["ночь", "ночи", "ночей"])}
            </div>
            <div className="col-discount ur-d-none">
              <span className="price-percent">-5%</span>
              <span className="price-discount ur-d-block ur-mt-7px">
                {(
                  Math.round(props.result.total) +
                  getDiscountNum(Math.round(props.result.total), 5)
                ).toLocaleString()}{" "}
                ₽
              </span>
            </div>
            <div className="col-buy">
              <button
                onClick={hotelOpen}
                className={
                  "ur-btn ur-btn-buy " +
                  (mainQuotasVacant > 0
                    ? `${names.backgrond.button.accent} ${names.text.negative}`
                    : `${names.backgrond.button.tertiary} ${names.text.secondary} not-sale`)
                }
              >
                {mainQuotasVacant > 0 ? (
                  <>
                    {minPrice.length > 1 && "от "}
                    {Math.round(minCost).toLocaleString()} ₽{" "}
                  </>
                ) : (
                  "Распродано"
                )}
                <i
                  className={`icon ${
                    isHotelOpen ? "icon-arrow-up" : "icon-arrow-down"
                  }`}
                ></i>
              </button>
              {mainQuotasVacant <= 0 && subscriptionButton}
            </div>
          </div>
        </div>
      </div>
      <div className="result-container__hotel-more">
        <div className={`ur-row row-header ${names.backgrond.tertiary}`}>
          <div className={`ur-col ur-col-1 ${names.text.tertiaryDark}`}>Тариф</div>
          <div className={`ur-col ur-col-2 ${names.text.tertiaryDark}`}>Услуги</div>
          <div className={`ur-col ur-col-3 ${names.text.tertiaryDark}`}>
            Стоимость за {diff} {declOfNum(diff, ["ночь", "ночи", "ночей"])}
          </div>
        </div>
        {hotelRates}
      </div>
    </div>
  );
}

export default HotelItem;
