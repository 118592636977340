import { toast } from 'react-toastify';

export function showSuccesAddCartNotification() {
    const headerBascet = document.querySelector('.header-basket');
    toast.success('Товар добавлен в корзину', {
        position: "bottom-right",
        autoClose: 2000,
        onClose: () => {
            if (headerBascet) headerBascet.click();
        }
    });
    if(!headerBascet) window.location =
        process.env.REACT_APP_API_URL +
            "index.php?route=checkout/checkout";
}
export function processBascetHtml({
    html, count
}) {
    if (window.$ && html) {
        window.$('.popup_basket').html(html)
        const btnToBasket = window.$('.btn-to-basket')
        if (btnToBasket.hasClass('disabled')) {
            btnToBasket
                .removeClass('disabled')
                .prop("disabled", false)
        }
    }
    showSuccesAddCartNotification()
    const cartTotal = document.querySelector('#cart-total')
    if (cartTotal && count) cartTotal.innerHTML = count
}

export function fetchRegenerateBasket(productId, countValue) {
    return new Promise((resolve, reject) => {
        const url = new URL(
            process.env.REACT_APP_API_URL + "index.php?route=common/header/regenerateBasket"
        );
        fetch(url, {
            method: "POST",
            body: {
                productId,
                countValue
            },
        })
            .then((res) => {
                if(res.status === 200) return res.json()
                return {
                    html: null,
                    count: null
                }
            })
            .then(result => resolve(result))
            .catch(err => reject(err))
    })
}

export function fetchAddHotelToCart(productId, formData) {
    let url = new URL(
        process.env.REACT_APP_API_URL + "index.php?route=checkout/cart/add"
    );
    return fetch(url, {
        method: "POST",
        body: formData,
    })
        .then((res) => {
            if(res.status === 200) return res.json()
            throw new Error('The server returned a bad response, status code: ' + res.status)
        })
        .then((result) => {
            if (!result.dataId) {
                return;
            }
            return fetchRegenerateBasket(productId, 1)
        })
        .then(processBascetHtml)
        .catch(err => console.log(err.message))
}

export const blockPageBody = () => {
  // document.body.style.overflow = "hidden"
}
export const unblockPageBody = () => {
  // document.body.style.overflow = "auto"
}